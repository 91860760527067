body {
  margin: 0;
  padding: 0;
  font-family: Roboto;
}

footer {
  font-size: 0.8em;
  max-height: 6px;
}
.selected{
  background-color: #000033
}